<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.name" placeholder="名称" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.type" placeholder="类型" filterable clearable>
						<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getCategories({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="create_drawer = true" :disabled="!$utils.create('categories')">添加分类</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="categories" :size="theme.size" @sort-change="sortChange">
				<el-table-column label="图标" width="60">
					<el-image slot-scope="scope" class="category_icon" :src="scope.row.icon" :preview-src-list="[scope.row.icon]">
						<i slot="error" class="el-icon-picture-outline"></i>
					</el-image>
				</el-table-column>
				<el-table-column prop="id" label="父级 > 名称 类型#ID" min-width="200" sortable="custom">
					<template slot-scope="scope">
						<h3><template v-if="scope.row.parent">{{scope.row.parent.name}} > </template> {{scope.row.name}} {{types[scope.row.type]}}#{{scope.row.id}}</h3>
						<div>
							<el-checkbox :value="scope.row.published_at != null" :size="theme.size" :disabled="!$utils.checkAuth('categories', 'publishing') || scope.row.deleted_at" @change="changeCategories({ action: 'publishing', id: scope.row.id})">发布</el-checkbox>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="关键词/描述" min-width="280">
					<template slot-scope="scope">
						<p>{{scope.row.keywords}}</p>
						<p>{{scope.row.description}}</p>
					</template>
				</el-table-column>
				<el-table-column label="模板" min-width="140">
					<template slot-scope="scope">
						<p v-if="scope.row.special">专题：<el-button type="text" @click="openSpecial(scope.row)">{{scope.row.special.name}}</el-button></p>
						<p v-else>列表：{{templates.index[scope.row.temp_index]}}</p>
						<p>详情：{{templates.show[scope.row.temp_show]}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
					<template slot-scope="scope">
						<p>创建：{{scope.row.created_at}}</p>
						<p>更新：{{scope.row.updated_at}}</p>
						<p v-if="scope.row.published_at">发布：{{scope.row.published_at}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="更多操作" width="140">
					<template slot-scope="scope">
						<el-button type="text" @click="$utils.openWindow($config.ROOTPATH+'categories/'+scope.row.id)" v-if="!scope.row.deleted_at">浏览</el-button>
						<el-button type="text" @click="handleEdit(scope.row)" :disabled="!$utils.update('categories') || scope.row.deleted_at">编辑</el-button>
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('categories')" v-if="scope.row.deleted_at">恢复</el-button>
						<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('categories')" v-else>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getCategories({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getCategories({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<el-drawer :title="category.id?'编辑类目':'添加类目'" :size="theme.width <= 550?'100%':'550px'" :visible.sync="create_drawer" @closed="resetForm('create_form')">
			<div style="padding: 0 15px">
				<el-form ref="create_form" label-width="80px" :model="category" :rules="rules" :size="theme.size">
					<el-form-item label="名称" prop="name">
						<el-input v-model="category.name" autocomplete="off" placeholder="请输入分类名称">
							<el-select v-model="category.type" slot="prepend" placeholder="类型" filterable>
								<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
							</el-select>
							<el-checkbox slot="append" v-model="category.bound" :true-label="true" :false-label="false">绑定专题</el-checkbox>
						</el-input>
					</el-form-item>
					<el-form-item label="父级" prop="categories">
						<el-cascader placeholder="请选择所属父级" v-model="category.categories" :props="parents" clearable></el-cascader>
					</el-form-item>
					<el-form-item label="图标" prop="icon">
						<el-upload
							class="category_uploader"
							:data="{type: category_icon_cfg.type}"
							:accept="category_icon_cfg.ext.map(e => { return '.'+e; }).join(',')"
							:on-success="(r, f, l) => { !r.code ? (category = {...category, icon: r.result.url}): $message.error(r.msg)}"
							:show-file-list="false"
							:action="$api.URI_UPLOADS"
							:before-upload="beforeCategoryIcon">
							<img v-if="category.icon" :src="category.icon" style="width: 80px; height: 80px; display: block;">
							<i v-else class="el-icon-plus" style="width: 80px; height: 80px; color: #8c939d; font-size: 28px; line-height: 80px; text-align: center;"></i>
							<div slot="tip" class="el-upload__tip">只能上传像素为80*80px内，大小不超过 {{category_icon_cfg.format_size_unit}}，格式为 {{category_icon_cfg.ext.join('/').toUpperCase()}} 的图像</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="绑定专题" prop="special_id" v-if="category.bound" :rules="[{ required: true, message: '请选择专题', trigger: 'blur' }]">
						<el-select placeholder="请输入专题名称" v-model="category.special_id" value-key="id" :remote-method="remoteMethod" :loading="specials_loading" remote filterable>
							<el-option v-for="special in specials" :key="special.id" :label="special.name" :value="special.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="列表模板" prop="temp_index" v-else :rules="[{ required: true, message: '请选择列表模板', trigger: 'blur' }]">
						<el-select v-model="category.temp_index" placeholder="请选择列表模板" filterable clearable>
							<el-option v-for="(template, t) in templates.index" :key="t" :label="template" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="详情模板">
						<el-select v-model="category.temp_show" placeholder="详情模板" filterable clearable>
							<el-option v-for="(template, t) in templates.show" :key="t" :label="template" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="关键词" prop="keywords">
						<el-input v-model="category.keywords" autocomplete="off" placeholder="请输入类目关键词" maxlength="168" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item label="描述" prop="description">
						<el-input type="textarea" rows="3" v-model="category.description" placeholder="用一段话简单描述类目内容。" maxlength="268" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
						<el-button @click="resetForm('create_form')">取 消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<style>
	.el-input .el-select .el-input {
		width: 80px;
	}
	.category_uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
	}
	.category_uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.category_icon {
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background-color: #f1f1f1;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme']),
			category_icon_cfg () {
				return this.$utils.uploadConfig('category_icon');
			}
		},
		methods: {
			async querySearchAsync (q, cb) {
				if (!q) return false;
				const res = await this.$http.get(this.$api.URI_SPECIALS, {params: {keyword: q}, headers: {loading: false}});
				const { code, msg, result } = res.data;
				if (code != 0) {
					this.$message.error(msg);
					return [];
				}
				let results = result.data.map((c) => ({value: c.name, id: c.id}));
				cb(results);
			},
			beforeCategoryIcon (file) {
				const { size, format_size_unit } = this.category_icon;
				const is = file.size < size;
				if (!is) return this.$message.error('上传分类图标不能超过 '+format_size_unit);
				return is;
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getCategories(this.params);
			},
			async submitCategory (data) {
				const res = await this.$http.post(this.$api.URI_CATEGORIES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.create_drawer = false;
						this.getCategories(this.params);
					}
				});
			},
			async changeCategories (r) {
				const res = await this.$http.get(this.$api.URI_CATEGORIES, {params: r, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getCategories(this.params);
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitCategory(this.category);
				});
			},
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.specials = [];
				this.category = {type: 'article'};
			},
			handleEdit (r) {
				this.category = {...r, bound: r.special?true:false, special_id: r.special?r.special.id:'' };
				if (r.special) {
					this.specials = [r.special];
				}
				this.create_drawer = true;
			},
			onDelete (r) {
				if (r.content_total) return this.$message.error('不能删除！【'+r.name+'】分类下还有'+r.content_total+'个内容。');
				if (r.special) return this.$message.error('不能删除！【'+r.name+'】分类下绑定《'+r.special.name+'》专题，请解除后再操作。');
				this.$confirm('确定要删除【'+r.name+'】分类吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CATEGORIES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getCategories(this.params);
					this.$message.success(msg);
				});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.name+'】分类吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CATEGORIES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getCategories(this.params);
					this.$message.success(msg);
				});
			},
			async getCategories (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CATEGORIES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.categories = result.data;
				this.types = result.types;
				this.templates = result.templates;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				types: [],
				specials: [],
				templates: [],
				categories: [],
				create_drawer: false,
				specials_loading: false,
				params: {
					perPage: 10,
				},
				parents: {
					lazy: true,
					value: 'id',
					label: 'name',
					checkStrictly: true,
					lazyLoad: async (node, resolve) => {
						const { value } = node;
						const res = await this.$http.get(this.$api.URI_CATEGORIES, {params: {action: 'category', type: this.category.type, category: value?value:0}, headers: {loading: true}});
						resolve(res.data.result);
					}
				},
				category: {
					type: 'article'
				},
				rules: {
					name: [
						{ required: true, message: '请输入分类名称', trigger: 'blur' }
					],
					categories: [
						{ type: 'array', message: '请选择上级分类', trigger: 'change' }
					]
				}
			}
		},
		created () {
			this.getCategories(this.params, true);
		}
	};
</script>